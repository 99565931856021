@font-face {
  font-family: "DRPublikRegular";
  src: local("DRPublikRegular"),
    url("./fonts/DRPublikRegular.ttf") format("truetype");
}

@font-face {
  font-family: "DRPublikBlack";
  src: local("DRPublikBlack"),
    url("./fonts/DRPublikBlack.ttf") format("truetype");
}

@font-face {
  font-family: "DRPublikBold";
  src: local("DRPublikBold"),
    url("./fonts/DRPublikBold.ttf") format("truetype");
}

@font-face {
  font-family: "DRPublikLight";
  src: local("DRPublikLight"),
    url("./fonts/DRPublikLight.ttf") format("truetype");
}

@font-face {
  font-family: "DRPublikSemiBold";
  src: local("DRPublikSemiBold"),
    url("./fonts/DRPublikSemiBold.ttf") format("truetype");
}

body {
  font-family: 'DRPublikRegular';
  background-color: #F6F6F6;
}