/* fonts.css */
@font-face {
    font-family: 'DRPublik';
    src: url('./DRPublikBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DRPublik';
    src: url('./DRPublikBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DRPublik';
    src: url('./DRPublikSemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DRPublik';
    src: url('./DRPublikRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'DRPublik';
    src: url('./DRPublikLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }